<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Mail History')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Subject')" prop="mailSubject">
            <el-input type="text" v-model="searchForm.mailSubject" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Send Date')" prop="sendDate">
            <el-date-picker
              v-model="searchForm.sendDate"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </el-form-item>
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin-top: 23.5px;"
            >
              Clear
            </tw-button>
          </div>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
    <tw-drawer parent="MailHistory" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import _ from 'lodash';
import { EMAIL_TRANSMISSION_HISTORIES_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, INITIAL_VIEW_FLG } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinRouteUtils from '@/utils/mixinRouteUtils.js';
import { getStartDate, getEndDate, initialFlag, clearSearchForm } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwHeader from '@/components/organisms/TwHeader';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import TwDrawer from '@/components/organisms/TwDrawer';
import schemas from '@/dictionaries/mailListSchema.json';

export default {
  name: 'MailList',
  mixins: [mixinRouteUtils, mixinEntityList],
  components: {
    TwButton,
    TwBreadcrumbs,
    TwHeader,
    TwTableSystem,
    TwDrawer
  },
  data() {
    return {
      schemas: schemas,
      items: null,
      selection: false,
      expand: false,
      searchForm: {
        mailSubject: '',
        sendDate: '',
      },
      defaultDate: dayjs().subtract(1, 'month').toDate(),
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      initial: 1,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Mail History')},
      ];
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.noticeHistoryId) {
        vm.$store.commit('SET_DRAWER', true);
      } else {
        vm.$store.commit('SET_DRAWER', false);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.noticeHistoryId) {
      this.$store.commit('SET_DRAWER', true);
    } else {
      this.$store.commit('SET_DRAWER', false);
    }
    next();
  },
  created() {
    const queries = this.$store.getters.getQueries('MAIL');
    if (queries) {
      this.searchForm = queries;
      this.search();
    } else {
      this.fetch();
    }
  },
  methods: {
    fetch(queries) {
      // bff_ms_1 メール送信履歴一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/email-transmission-histories/search',
          query: queries || {
            initialFlag: INITIAL_VIEW_FLG.OFF, //INITIAL_VIEW_FLG.ON,
            limit: this.limit,
            offset: this.offset,
            sort: this.sort || undefined,
          }
        }
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.emailTransmissionHistories;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      const queries = {
        sendStartDate: getStartDate(this.searchForm.sendDate),
        sendEndDate: getEndDate(this.searchForm.sendDate),
        mailSubject: this.searchForm.mailSubject,
      };
      this.$store.commit('SET_QUERIES', {key: 'MAIL', queries: _.cloneDeep(this.searchForm)});
      this.initial = initialFlag(queries);
      this.fetch({
        ...queries,
        initialFlag: this.initial,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort || undefined,
      });
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'MAIL', queries: _.cloneDeep(this.searchForm)});
    },
    rowClick(row) {
      if (this.$route.params.noticeHistoryId === row.noticeHistoryId) {
        return;
      }

      const offset = _.findIndex(this.items, {noticeHistoryId: row.noticeHistoryId}) + this.offset - 1;

      setTimeout(() => {
        this.$router.push({
          name: 'MailDetail',
          params: { noticeHistoryId: row.noticeHistoryId},
          query: { offset: offset, start: getStartDate(this.lastQuery.sendDate) || undefined, end: getEndDate(this.lastQuery.sendDate) || undefined, subject: this.lastQuery.mailSubject || undefined, initial: this.initial, sort: _.get(this.sort, 'sortType') || undefined, target: _.get(this.sort, 'target') || undefined },
        });
      });
    },
    rowDbClick(row) {
      const offset = _.findIndex(this.items, {noticeHistoryId: row.noticeHistoryId}) + this.offset - 1;
      this.linkToOtherWindow({
        name: 'MailDetailFull',
        params: { noticeHistoryId: row.noticeHistoryId },
        query: { offset: offset, start: getStartDate(this.lastQuery.sendDate) || undefined, end: getEndDate(this.lastQuery.sendDate) || undefined, subject: this.lastQuery.mailSubject || undefined, initial: this.initial, sort: _.get(this.sort, 'sortType') || undefined, target: _.get(this.sort, 'target') || undefined },
      });
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [EMAIL_TRANSMISSION_HISTORIES_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort || undefined,
      };
      this.fetch(queries);
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .side_drawer {
    width: 780px;
    .inner {
      padding: 20px 40px 48px;
    }
  }
</style>
